<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        meigaranews {{perf}}
        <DataTable :value="meigaranewses" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows
        :lazy="true" :paginator="true" :rows="10" ref="dt" dataKey="id"
        :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        selectionMode="single"
        v-model:filters="filters" @filter="onFilter($event)" filterDisplay="row">
          <Column field="id" header="No" :sortable="true"></Column>
          <Column field="news_sentiment" header="感情" filterField="news_sentiment" filterMatchMode="contains" ref="news_sentiment" :sortable="true" :style="{'min-width': '10rem'}">
            <template #body="slotProps">
              <span :class="'sentiment-badge status-' + slotProps.data.news_sentiment.toLowerCase()">{{slotProps.data.news_sentiment}}</span>
            </template>
            <template #filter="{filterModel,filterCallback}">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
            </template>
          </Column>  
          <Column field="news_tags" header="タグ" :sortable="true"></Column>  
          <Column field="meigara_name" header="銘柄名" :sortable="true"></Column>     
          <Column field="meigara_cd" header="銘柄コード" filterField="meigara_cd" filterMatchMode="contains" ref="meigara_cd" :sortable="true" :style="{'min-width': '10rem'}">
            <template #filter="{filterModel,filterCallback}">
            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
            </template>
          </Column>
          <Column field="news_datetime" header="日時" filterField="news_datetime" filterMatchMode="contains" ref="news_datetime" :sortable="true" :style="{'min-width': '10rem'}">
            <template #filter="{filterModel,filterCallback}">
            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="日時" />
            </template>
          </Column>              
          <Column field="news_type" header="タイプ" filterField="news_type" filterMatchMode="contains" ref="news_type" :sortable="true" :style="{'min-width': '10rem'}">
            <template #filter="{filterModel,filterCallback}">
            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
            </template>
          </Column>                                                  
          <Column field="news_overview" header="概要" filterField="news_overview" filterMatchMode="contains" ref="news_overview" :sortable="true" :style="{'min-width': '10rem'}">
            <template #filter="{filterModel,filterCallback}">
            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
            </template>
          </Column>
          <Column field="news_url" header="URL" :sortable="true"></Column>
          <Column field="info_source" header="情報源" filterField="info_source" filterMatchMode="contains" ref="info_source" :sortable="true" :style="{'min-width': '10rem'}">
            <template #filter="{filterModel,filterCallback}">
            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
            </template>            
          </Column>
          <Column field="info_source2" header="情報源2" :sortable="true"></Column>
          <Column field="udatetime" header="更新日時" :sortable="true"></Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import MeigaranewsService from '@/service/MeigaranewsService';

export default {
  inject: ['dialogRef'],
  data() {
    return {
      loading: false,
      totalRecords: 0,
      meigaranewses: null,
      lazyParams: {},
      filters1: {},
      filters: {
        'news_datetime': {value: '', matchMode: 'contains'},
        'news_type': {value: '', matchMode: 'contains'},
        'news_overview': {value: '', matchMode: 'contains'},
        'news_sentiment': {value: '', matchMode: 'contains'},
        'meigara_cd': {value: '', matchMode: 'contains'},
        'info_source': {value: '', matchMode: 'contains'},
      },
      perf: null,
      meigaraCode: null
    }
  },
  meigaranewsService: null,
  created() {
    this.meigaranewsService = new MeigaranewsService();
  },
  mounted() {
    console.log('Meigaranews Mounted');

    if (this.dialogRef) {
      this.meigaraCode = this.dialogRef.data.meigaraCode;
    }

    this.loading = true;

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: "news_datetime",
      sortOrder: "-1",
      filters: this.filters
    };

    this.loadLazyData();
  },
  methods: {
    loadLazyData() {
      this.loading = true;

    setTimeout(() => {
      this.meigaranewsService.getAll(
        {dt_params: JSON.stringify( this.lazyParams )}, this.meigaraCode)
          .then(data => {
              this.meigaranewses = data.payload.data;
              this.totalRecords = data.payload.total;
              this.loading = false;
              this.perf = data.perf;
        }
      );
    }, Math.random() * 1000 + 250);
    },
    onPage(event) {
      this.lazyParams = event;
      this.loadLazyData();
    },
    onSort(event) {
      this.lazyParams = event;
      this.loadLazyData();
    },
    onFilter() {
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/badges.scss';
</style>